<template>
    <!-- 机构填报项维护 -->
    <div class="pageContol">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">基础信息</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">填报项维护</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <!-- 搜索区域 -->
                <div class="operationControl flexdc" style="align-items:flex-start">
                    <div class="searchbox" style="margin-bottom:15px">
                        <div title="信息项名称" class="searchboxItem ci-full">
                            <span class="itemLabel" style="width:150px;">信息项名称:</span>
                            <el-input v-model="searchForm.attributeLabel" placeholder="请输入信息项名称" type="text" clearable
                                size="small" />
                        </div>
                        <div title="信息项分类" class="searchboxItem ci-full flexcc">
                            <span class="itemLabel">信息项分类:</span>
                            <el-select v-model="searchForm.groupKey" placeholder="请选择" clearable size="small">
                                <el-option v-for="item in groupKeyList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="信息项类型" class="searchboxItem ci-full flexcc">
                            <span class="itemLabel">信息项类型:</span>
                            <el-select v-model="searchForm.attributeType" placeholder="请选择" clearable size="small">
                                <el-option v-for="item in attributeTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="状态" class="searchboxItem ci-full flexcc">
                            <span class="itemLabel">状态:</span>
                            <el-select v-model="searchForm.enable" placeholder="请选择" clearable size="small">
                                <el-option v-for="item in enableOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                            <el-button type="primary" class="bgc-bv" @click="newlyBuild(null)" round>新增</el-button>
                        </div>
                    </div>
                </div>
                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table ref="multipleTable" :data="listData" :height="tableHeight" size="small"
                            tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
                            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50" />
                            <el-table-column prop="attributeLabel" label="信息项名称" align="center" />
                            <el-table-column label="信息项类型" align="center">
                                <template v-slot="{ row }">
                                    {{ getAttributeTypeLabel(row.attributeType) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="信息项分类" align="center">
                                <template v-slot="{ row }">
                                    {{ $setDictionary("CT_GROUP_KEY", row.groupKey) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="示例图" align="center">
                                <template slot-scope="scope" v-if="scope.row.sampleKeyUrl != null">
                                    <el-image style="height: 40px" :src="scope.row.sampleKeyUrl"
                                        :preview-src-list="[scope.row.sampleKeyUrl]">
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-folder-delete"></i>
                                        </div>
                                    </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column label="文件大小限制" align="center">
                                <template slot-scope="{row}" >
                                  <template v-if="row.groupKey=='1_UPLOAD'">
                                    {{(typeof(row.attributeFileSizeMin) == "number"&&typeof(row.attributeFileSizeMax) == "number")?`${row.attributeFileSizeMin} - ${row.attributeFileSizeMax}k`:'无限制'}}
                                  </template>
                                  <template v-else>
                                    --
                                  </template>
                                </template>
                            </el-table-column>
                            <el-table-column label="模板" align="center">
                                <template slot-scope="scope" v-if="scope.row.templateKeyUrl != null">
                                    <el-link type="primary" :href="scope.row.templateKeyUrl" target="_blank">下载模板</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column label="状态" align="center">
                                <template v-slot="{ row }">
                                    {{ row.enable === true ? '启用' : "停用" }}
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="200px">
                                <template v-slot="{ row }">
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                        @click="newlyBuild(row)">编辑</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="setEnable(row)">{{
                                        row.enable === false ? "启用" : "停用" }}</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                        @click="move(row, true)">上移</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                        @click="move(row, false)">下移</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange" />
            </div>
        </div>
        <!-- 对话框 -->
        <el-dialog :title="dialogTitle === 1 ? '新增信息项' : '编辑信息项'" :visible.sync="dialogVisible" @closed="dialogClosed"
            width="900px" top="20px">
            <el-form ref="dialogForm" :rules="rules" :model="dialogForm" size="small" label-width="100px">
                <el-form-item label="信息项名称" prop="attributeLabel">
                    <el-input v-model="dialogForm.attributeLabel" placeholder="请输入信息项名称"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="dialogForm.attributeDescribe" maxlength="20" show-word-limit
                        placeholder="请输入描述"></el-input>
                </el-form-item>
                <el-form-item label="信息项分类" prop="groupKey">
                    <el-select v-model="dialogForm.groupKey" placeholder="请选择" @change="changeGroupKey">
                        <el-option v-for="item in groupKeyList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="信息项类型" prop="attributeType">
                    <el-select v-model="dialogForm.attributeType" placeholder="请选择" @change="changeAttributeType">
                        <el-option v-for="item in attributeTypeOptions" :key="item.value" :label="item.label"
                            :value="item.value" :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="dictionaryKey" label="选项名称" prop="dictionaryKey" v-if="3 == dialogForm.attributeType">
                    <el-radio-group class="dictionaryKey_1" v-model="dialogForm.dictionaryKey"
                        @change="changeDictionaryKey">
                        <el-radio :label="item.value" v-for="(item, index) in usableKeyList" :key="index">{{ item.label
                        }}</el-radio>
                    </el-radio-group>
                    <div class="dictionaryKey_2">
                        <p>选项内容<i class="el-icon-d-arrow-right"></i></p>
                    </div>
                    <div class="dictionaryKey_3">
                        <p v-for="(item, index) in dictionaryKeyShowListInfo" :key="index">{{ item.label }}</p>
                    </div>
                </el-form-item>
                <el-form-item label="默认图片" prop="sampleKey" v-if="2 == dialogForm.attributeType">
                    <el-upload :on-change="handleSampleChange" :before-upload="$beforeAvatarUpload"
                        :http-request="$requestMine" :show-file-list="false" class="img-el-upload" action
                        accept="image/png, image/gif, image/jpg, image/jpeg">
                        <el-image :src="dialogForm.sampleKeyUrl || require('@/assets/develop.png')" fit="contain"
                            class="imgCenter"></el-image>
                    </el-upload>
                </el-form-item>
                <el-form-item label="文件大小" v-if="2 == dialogForm.attributeType">
                  <el-input v-model="dialogForm.attributeFileSizeMin" style="width: 100px;" size="small" onkeyup="value=value.match(/\d+/)"></el-input> k
                  <span style="width: 40px;text-align: center;">到</span>
                  <el-input v-model="dialogForm.attributeFileSizeMax" style="width: 100px;" size="small" onkeyup="value=value.match(/\d+/)"></el-input> k
                </el-form-item>
                <el-form-item label="下载模板" prop="templateKey" v-if="2 == dialogForm.attributeType">
                    <el-upload v-model="dialogForm.templateKey" v-if="this.missingTemplate" class="upload-btn_1"
                        :action="actionUrl" :on-error="handleError" :on-success="handleSuccess"
                        :on-change="handleTemplateChange" :show-file-list="false" :auto-upload="false">
                        <el-button size="small" class="bgc-bv">上传文件</el-button>
                    </el-upload>
                    <span v-else>
                        <el-link type="primary" :href="this.dialogForm.templateKeyUrl" target="_blank"
                            style="margin-right: 10px">下载模板</el-link>
                        <el-link type="primary" @click="reomveFile">删除</el-link>
                    </span>
                </el-form-item>
                <el-form-item label="默认选中" prop="defaultUse">
                    <el-select v-model="dialogForm.defaultUse" placeholder="请选择">
                        <el-option v-for="item in useOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="customBtn" round @click="dialogClosed">取 消</el-button>
                <el-button class="customBtn" round type="primary" @click="submit">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "evaluationMaintenance",
    components: {
        Empty,
        PageNum
    },
    mixins: [List],
    data() {
        return {
            // 搜索表单
            searchForm: {
                attributeLabel: "",  //信息项名称
                attributeDescribe: "", // 描述
                groupKey: "",        //信息项分类(CT_GROUP_KEY)
                attributeType: "",   //信息项类型: 1-文本输入, 2-文件上传, 3-手机号
                enable: "",          //是否使用
            },
            // 信息项类型
            attributeTypeOptions: [
                {
                    value: 1,
                    label: '文本输入',
                }, {
                    value: 2,
                    label: '文件上传',
                }, {
                    value: 3,
                    label: '下拉框',
                }, {
                    value: 4,
                    label: '日期',
                }, {
                    value: 9,
                    label: '身份证号',
                }, {
                    value: 5,
                    label: '行政区划（省市县）',
                }, {
                    value: 10,
                    label: '行政区划（省）',
                }, {
                    value: 11,
                    label: '行政区划（省市）',
                }, {
                    value: 6,
                    label: '数值（整数）',
                }, {
                    value: 7,
                    label: '数值（限3位小数）',
                }, {
                    value: 8,
                    label: '申报条件',
                }
            ],
            // 状态列表
            enableOptions: [
                {
                    value: true,
                    label: '启用',
                }, {
                    value: false,
                    label: '停用',
                }
            ],
            // 默认选择
            useOptions: [
                {
                    value: true,
                    label: '是',
                }, {
                    value: false,
                    label: '否',
                }
            ],
            // 分页器数据
            pagination: {
                total: 0,//总条数
                size: 10,//每页几条数据
                current: 1//当前页
            },
            // 对话框表单数据
            dialogForm: {
                attributeId: "", //属性id
                attributeLabel: "", //信息项名称
                attributeType: "", //信息项类型
                groupKey: "", //信息项分类
                attributeFileSizeMin: '',//文件最小
                attributeFileSizeMax: '',//文件最大
                defaultUse: "", //是否默认选中
                sampleKey: "", //示例图key
                sampleKeyUrl: "",
                templateKey: "", //下载模板key
                templateKeyUrl: "",
            },
            // 表格数据
            listData: [],
            // 对话框可见性
            dialogVisible: false,
            // 信息项分类数据
            groupKeyList: [],
            // 选项名称下拉数据
            usableKeyList: [],
            // 弹框中选项名称的子集内容
            dictionaryKeyShowListInfo: [],
            // 对话框标题
            dialogTitle: 1, // 1--新增  2--编辑
            // 表单规则
            rules: {
                attributeLabel: [
                    { required: true, message: "请输入信息项名称", trigger: "blur" }
                ],
                attributeType: [
                    { required: true, message: "请选择信息项类型", trigger: "change" }
                ],
                groupKey: [
                    { required: true, message: "请选择信息项分类", trigger: "change" }
                ],
                defaultUse: [
                    { required: true, message: "请选择默认选中状态", trigger: "change" }
                ],
                dictionaryKey: [
                    { required: true, message: "请选择选项名称", trigger: "change" }
                ],
            },
        }
    },
    methods: {
        // 序号
        indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
        },
        // 初始化
        initData() {
            // 获取列表数据
            this.getListData();
            // 获取培训等级字典数据
            this.getDictionaries("CT_GROUP_KEY", this.groupKeyList);
            this.getDictionaries("CT_ATTRIBUTE_USABLE_KEY", this.usableKeyList);
        },
        // 选择信息项分类 - 动态处理信息项类型数据
        changeGroupKey(val, type) {
            if (type != 'edit') {
                this.dialogForm.attributeType = '';
            }
            this.disValue(val)
            this.dialogForm.attributeFileSizeMin = '';
            this.dialogForm.attributeFileSizeMax = '';
        },
        // 禁用选项
        disValue(val){
          if (val == '1_UPLOAD') {
              for (let i = 0; i < this.attributeTypeOptions.length; i++) {
                  if (this.attributeTypeOptions[i].value != 2) {
                      this.attributeTypeOptions[i].disabled = true;
                  } else {
                      this.attributeTypeOptions[i].disabled = false;
                  }
              }
          } else {
              for (let i = 0; i < this.attributeTypeOptions.length; i++) {
                  if (this.attributeTypeOptions[i].value == 2) {
                      this.attributeTypeOptions[i].disabled = true;
                  } else {
                      this.attributeTypeOptions[i].disabled = false;
                  }
              }
          }
        },
        // 选择信息项类型 - 判断是否先选择信息项分类
        changeAttributeType(val) {
            if (!this.dialogForm.groupKey) {
                this.$message({
                    type: "warning",
                    message: "请先选择信息项分类",
                });
                this.dialogForm.attributeType = '';
            }
        },
        // 获取信息项分类字典数据
        getDictionaries(key, dataObj) {
            const dictDate = this.$setDictionary(key, "list");
            Object.keys(dictDate).forEach(item => {
                dataObj.push({
                    label: dictDate[item],
                    value: item
                });
            });
        },
        // 获取列表数据
        getListData(current) {
            if (current) this.pagination.current = current;
            const { searchForm, pagination } = this;
            var param = {
                pageNum: pagination.current,
                pageSize: pagination.size,
            }
            if (searchForm.attributeLabel) {
                param.attributeLabel = searchForm.attributeLabel.trim();
            }
            if (searchForm.attributeType) {
                param.attributeType = searchForm.attributeType;
            }
            if (searchForm.groupKey) {
                param.groupKey = searchForm.groupKey;
            }
            if (null != typeof (searchForm.enable)) {
                param.enable = searchForm.enable;
            }
            this.$post("/biz/ct/attribute/page", param).then(res => {
                this.listData = res?.data?.list || [];
                pagination.total = res?.data?.total || 0;
            }).catch(e => {
                console.error("获取机构填报属性列表出错", e);
            });
        },
        // 获取信息类型属性标签
        getAttributeTypeLabel(key) {
            for (let k in this.attributeTypeOptions) {
                if (this.attributeTypeOptions[k].value === key) {
                    return this.attributeTypeOptions[k].label;
                }
            }
        },
        // 新建或者编辑信息项
        newlyBuild(row) {
            //默认无已上传模板
            this.missingTemplate = true;
            //为空时新增
            if (!row) {
                this.dialogTitle = 1;
                for (let item in this.dialogForm) {
                    this.dialogForm[item] = "";
                }
            } else {
                this.dialogTitle = 2;
                this.dialogForm = { ...row };
                if (this.dialogForm.templateKeyUrl) {
                    this.missingTemplate = false;
                }
                this.disValue(this.dialogForm.groupKey);
                if(this.dialogForm.attributeType == 3){
                    this.getDictionaries(this.dialogForm.dictionaryKey, this.dictionaryKeyShowListInfo);
                }
            }
            this.dialogVisible = true;
        },
        // 点击选项名称事件
        changeDictionaryKey(val) {
            this.dictionaryKeyShowListInfo = [];
            this.getDictionaries(val, this.dictionaryKeyShowListInfo);
        },
        // 关闭弹框
        dialogClosed() {
            this.dictionaryKeyShowListInfo = [];
            this.$refs.dialogForm.resetFields();
            for (let item in this.dialogForm) {
                this.dialogForm[item] = "";
            }
            this.dialogVisible = false;
        },
        // 图片上传
        handleSampleChange(res, fileList) {
            let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
            let formData = new FormData();
            formData.append("folder ", "MATERIALS");
            formData.append("file ", res.raw);
            formData.append("fileType ", extension);
            formData.append("isPublic ", false);
            const isLt2M = res.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 5MB!");
            } else if (
                ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
                    res.raw.type
                ) === -1
            ) {
                this.$message.error("请上传正确的图片格式");
                return false;
            } else {
                this.$Postformat("/sys/upload", formData)
                    .then((result) => {
                        this.$set(this.dialogForm, "sampleKeyUrl", result.data.fileURL);
                        this.$set(this.dialogForm, "sampleKey", result.data.fileKey);
                    })
                    .catch(() => {
                        setTimeout(() => {
                            this.$message({
                                type: "warning",
                                message: "上传失败",
                            });
                        }, 300);
                    });
            }
        },
        // 上传模板
        handleTemplateChange(res, fileList) {
            let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
            let formData = new FormData();
            formData.append("folder ", "MATERIALS");
            formData.append("file ", res.raw);
            formData.append("fileType ", extension);
            formData.append("isPublic ", false);
            const isLt2M = res.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                this.$message.error("上传文件大小不能超过 5MB!");
            } else if (
                ["pdf", "xls", "xlsx", "doc", "docx", "ppt", "pptx"].indexOf(
                    extension.toLowerCase()
                ) === -1
            ) {
                this.$message.error("请上传正确的文件格式, 包含 pdf、word、excel、ppt");
                return false;
            } else {
                this.$Postformat("/sys/upload", formData)
                    .then((result) => {
                        this.$set(this.dialogForm, "templateKeyUrl", result.data.fileURL);
                        this.$set(this.dialogForm, "templateKey", result.data.fileKey);
                        if (this.dialogForm.templateKeyUrl) {
                            this.missingTemplate = false;
                        }
                    }).catch(() => {
                        setTimeout(() => {
                            this.$message({
                                type: "warning",
                                message: "上传失败",
                            });
                        }, 300);
                    });
            }
        },
        // 移除文件
        reomveFile() {
            this.dialogForm.templateKeyUrl = "";
            this.dialogForm.templateKey = "";
            this.missingTemplate = true;
        },
        // 表单提交
        submit() {
            const { dialogForm, dialogTitle } = this;
            // 如果是新增，不需要传工种id
            this.$refs.dialogForm.validate(valid => {
                if (valid) {
                    if(this.isNum(dialogForm.attributeFileSizeMin)&&
                      this.isNum(dialogForm.attributeFileSizeMax)&&
                      dialogForm.attributeFileSizeMin>=dialogForm.attributeFileSizeMax){
                      this.$message.error('文件大小区间不正确！')
                      return
                    }
                    //非编辑模式不传 属性id
                    if (dialogTitle === 1) {
                        delete dialogForm.attributeId;
                    }
                    //非文件上传类，不传 示例图和模板
                    if (2 != dialogForm.attributeType) {
                        delete dialogForm.sampleKey;
                        delete dialogForm.templateKey;
                    }
                    //非下拉类，不传 选项内容
                    if (3 != dialogForm.attributeType) {
                        delete dialogForm.dictionaryKey;
                    }
                    this.$post("/biz/ct/attribute/save", dialogForm).then(() => {
                        this.getListData();
                        this.$message.success(dialogTitle === 1 ? "信息项添加成功" : "信息项修改成功");
                        this.dialogVisible = false;
                    }).catch(e => {
                        console.error("信息项保存异常", e);
                    });
                }
            });
        },
        isNum(val){
          if(val===undefined||val===null||val===''){
            return false
          }else{
            return true
          }
        },
        // 启用/停用
        setEnable(row) {
            const { attributeId, enable } = row;
            const title = enable === true ? "停用" : "启用";
            this.$confirm(`确认${title}该信息项?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                confirmButtonClass: "bgc-bv",
            }).then(() => {
                this.$post("/biz/ct/attribute/enable", {
                    attributeId: attributeId,
                    enable: !enable,
                }).then(() => {
                    this.getListData();
                    this.$message.success("操作成功");
                });
            });
        },
        // 上移/下移操作
        move(row, isUp) {
            this.$post("/biz/ct/attribute/move", {
                attributeId: row.attributeId,
                upMove: isUp,
            }).then(() => {
                this.getListData();
                this.$message.success("操作成功");
            });
        },
        // 当前页改变
        currentChange(current) {
            this.pagination.current = current;
            this.getListData();
        },
        // 当前页数改变
        sizeChange(size) {
            this.pagination.size = size;
            this.getListData();
        },
        // 设置表格高度
        getTableHeight(opDom = false, page = true) {
            let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
            if (opDom) tHeight -= 40 + 0.675 * 16 + 1;
            if (page) tHeight -= 32;
            this.tableHeight = tHeight;
        },
    },
    created() {
        this.initData();
    }
}
</script>

<style lang="less" scoped>
/deep/ .upload-btn_1 {
    .el-upload {
        height: 40px !important;
        border: none !important;
    }
}

.dictionaryKey /deep/ .el-form-item__content {
    display: flex;
    justify-content: flex-start;
    height: 340px;

    .dictionaryKey_1 {
        height: 100%;
        overflow-y: auto;
        .el-radio {
            display: block;
        }
    }

    .dictionaryKey_2 {
        display: flex;
        align-items: center;
        text-align: center;
        width: 100px;
    }

    .dictionaryKey_3 {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        p {
            line-height: 24px;
        }
    }

}</style>